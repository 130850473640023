<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Feedback to Psychological Disorders Post - Part 2</h2>
    </v-row>
    <v-form v-for="question in questions" :key="question.inputName">
      <div class="mb-4">Example Student Post:</div>
      <p class="mb-8 mt-8">"{{ examplePosts[0].text }}"</p>
      <v-row class="mb-4 mt-4">
        {{ question.text }}
      </v-row>
      <div>
        <s-textarea v-model="inputs[question.inputName]" class="mb-4" outlined />
      </div>
    </v-form>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'UPEIPsych1020A6Task3Part2',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        feedbackAnswer: null,
      },
      questions: [
        {
          text: 'Please provide appropriate feedback to this example post made by a student about Psychological Disorders.',
          inputName: 'feedbackAnswer',
        },
      ],
      examplePosts: [
        {
          postNumber: 0,
          text: 'In the first video, “Tale of mental illness from the inside” Elyn Saks talks about her journey battling Schizophrenia. I found it interesting when she talked about restraints. When I thought about restraints I just thought that’s what they did to calm patients down. Now that I hear her story it made me realize that it does the opposite of calming and can actual be a very terrifying and traumatic experience. Why should someone battling with mental illness have to go through such inhumane processes and come out more traumatized and invalidated? The voices in my head, by Eleanor Longden made me think about cultural aspects of mental health. To be honest I didn’t know much about culture until I took anthropology in first semester. I find it’s easy to only think about your own culture and your own way of doing things when you don’t know a difference. Psychiatrist breaks down down psychotic episodes in movies by Eric Bender was the video I was the most excited for. I never thought about the fact that psychotic episodes in movies meant anything, I just seen it as acting. Before listening to this video, I didn’t think that these movies were also creating a stigma around mental health. I guess it’s true that they’re making people think that these scenes in their movies are what people with mental health issues look like. These three videos for these week were intertwined because they were all in regards to mental health. I feel like the last two connected the most in regards to culture and/or where you live. These types of movies are also part of culture where as in other cultures they types of movies aren’t watched. It makes me wonder, if one culture watched these movies and one didn’t would there be a difference in behaviour?',
        },
      ],
    };
  },
};
</script>
